<!-- eslint-disable vue/no-multiple-template-root -->
<template>
  <UDashboardLayout>
    <UDashboardPanel
      :width="250"
      :resizable="{ min: 200, max: 300 }"
      collapsible
    >
      <UDashboardNavbar
        class="!border-transparent"
        :ui="{ left: 'flex-1' }"
      >
        <template #left>
          <!-- <UAvatar
            src="/icon.png"
            class="ml-3"
          /> -->
          <div class="w-full bg-[#fdfdfd]">
            <img src="/icon.png" alt="Simpliflow Logo Image" class="h-[50px]">
          </div>
          <!-- <TeamsDropdown /> -->
        </template>
      </UDashboardNavbar>

      <UDashboardSidebar>
        <UDashboardSidebarLinks :links="links" />

        <!-- <UDivider />

        <UDashboardSidebarLinks
          :links="[{ label: 'Colors', draggable: true, children: colors }]"
          @update:links="colors => defaultColors = colors"
        />

        -->
        <div class="flex-1" />

        <UDashboardSidebarLinks :links="footerLinks" />
      </UDashboardSidebar>
    </UDashboardPanel>

    <slot />

    <!-- ~/components/HelpSlideover.vue -->
    <HelpSlideover />
  </UDashboardLayout>
</template>

<script setup lang="ts">
import { UserRoles } from '~/types/consts';

const route = useRoute()
const { clearToken, role, name } = useAuthSession();
const { isHelpSlideoverOpen } = useDashboard()

const links = computed(() => {
  const linksList = [{
    id: 'clients',
    label: 'Clients',
    icon: 'i-heroicons-user-group',
    to: '/clients',
    tooltip: {
      text: 'Users',
      shortcuts: ['G', 'C']
    }
  }, {
    id: 'users',
    label: 'Users',
    icon: 'i-heroicons-user-group',
    to: '/users',
    tooltip: {
      text: 'Users',
      shortcuts: ['G', 'U']
    }
  }]

  if ((role.value === UserRoles.SUPERADMIN)) {
    linksList.push({
      id: 'organizations',
      label: 'Organizations',
      icon: 'i-heroicons-building-office-2',
      to: '/organizations',
      tooltip: {
        text: 'Organizations',
        shortcuts: ['G', 'O']
      }
    });
  }
  return linksList
})

const footerLinks = [{
    id: 'settings',
    label: 'Settings',
    to: '/settings',
    icon: 'i-heroicons-cog-8-tooth',
  }, {
  label: 'Help & Support',
  icon: 'i-heroicons-question-mark-circle',
  click: () => isHelpSlideoverOpen.value = true
}, {
    id: 'signout',
    label: 'Sign out',
    icon: 'i-heroicons-arrow-left-on-rectangle',
    click: async () => {
      // Clear the user's session. Effectively logging out.
      await clearToken();
      navigateTo('/auth/login');
    }
  }]


// TODO: not sure what these are for
// const defaultColors = ref(['green', 'teal', 'cyan', 'sky', 'blue', 'indigo', 'violet'].map(color => ({ label: color, chip: color, click: () => appConfig.ui.primary = color })))
// const colors = computed(() => defaultColors.value.map(color => ({ ...color, active: appConfig.ui.primary === color.label })))
</script>
